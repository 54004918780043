<template>
    <fullscreen-loader message="Connecting to Sonos..." />
</template>

<script>
import FullscreenLoader from '@/components/generic/FullscreenLoader';
import {Notification} from 'element-ui';
import {firebaseApp} from '@/utils/firebase';
import {handleCallbackFromSonos} from '@/api/sonos';

export default {
    name: 'sonos',
    components: {
        FullscreenLoader,
    },
    data() {
        return {};
    },
    created() {
        // wait for user auth before calling xero auth
        firebaseApp.auth().onAuthStateChanged(() => {
            this.authenticate();
        });
    },
    methods: {
        async authenticate() {
            const {code, error, error_description} = this.$route.query;

            this.clearQueryString();

            if (error || !code) {
                console.log('Received invalid response from Sonos');
                Notification({
                    type: 'error',
                    title: 'Unable to connect to Sonos',
                    message:
                        'Please try authenticating again.' +
                        (error_description || error
                            ? ` (Error: ${error_description || error})`
                            : ''),
                });
                this.navigateToHome();
                return;
            }

            const result = await handleCallbackFromSonos(code);

            if (result.success) {
                Notification({
                    type: 'success',
                    title: 'Success',
                    message: 'Sonos connected!',
                });
                this.navigateToHome();
            } else {
                Notification({
                    type: 'error',
                    title: 'Unable to connect to Sonos',
                    message: 'Please try authenticating again.',
                });
                this.navigateToHome();
            }
        },
        clearQueryString() {
            window.history.replaceState(null, '', window.location.pathname);
        },
        navigateToHome() {
            this.$router.push({name: 'home'}).catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped></style>
